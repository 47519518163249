<template>
    <div id="hotel_info">
      <iq-card body-class=" profile-page p-0">
      <template v-slot:body>
        <div class="hotel-info">
          <div class="hotel-info__header">
            <h3>
            {{ hotel.title }}
          </h3>
          <div>
            <b-button @click="toogleShowing()" v-if="canAccess(['showInWebsite-hotel'])" class="mb-1" v-b-tooltip.top="hotel.show_in_website ? 'Disable in Website' : 'Show in Website'" :variant="hotel.show_in_website?' iq-bg-danger':' iq-bg-success'"  size="md">
              <span v-if="!hotel.show_in_website">
                <i class="ri-eye-off-line" ></i>
                {{ $t('hotel-show') }}
              </span>
              <span v-else>
                <i class="ri-eye-line"></i>
                {{ $t('hotel-hide') }}
              </span>
          </b-button>
          <b-button @click="toogleActive()" v-if="canAccess(['active-hotel'])" class="mx-2 mb-1" v-b-tooltip.top="hotel.is_active ? 'Disactive Hotel' : 'Active Hotel'" variant=" iq-bg-primary"   size="md"><i class="ri-shut-down-line"></i> {{ $t('hotel-active') }} </b-button>

          </div>
          </div>
          <div class="hotel-info__sub">
            <span>
              {{ hotel.address || 'No Address' }}
            </span>
            <span>
              |
            </span>
            <span>
              {{ hotel.start_price }} $ - {{ hotel.end_price }} $
            </span>
            <span>
              |
            </span>
            <div class="text-center">
              <span v-for="star in hotel.stars" :key="star">
                <i class="ri-star-fill" style="color: gold"></i>
              </span>
            </div>
            <span>
              |
            </span>
            <div class="text-center">
                {{ hotel.total_views }} {{$t('views')}}
            </div>
            <span>
              |
            </span>
            <span>
              {{ hotel.has_pool ? 'Has Pool' : 'Without Pool' }}
            </span>
          </div>

          <div class="hotel-info__details">
            <div class="row">
              <div class="col-md-4">
                <img :src="hotel.image" width="100" class="hotel-info__thumbnail"/>
              </div>
              <div class="col-md-8">
                {{ hotel[`${$i18n.locale}_description`] }}
              </div>
            </div>
          </div>
        </div>
      </template>
     </iq-card>

      <div id="info-section" class="position-relative mt-1">
        <iq-card>
            <template v-slot:body>
                <div class="tabs">
                    <ul class="list-unstyled p-0 mb-0 d-flex align-items-center">
                        <li :class="activeTab === 0 ? 'active':''" @click="activeTab = 0" >
                            <i class="far fa-check-circle"> </i>
                            {{ $t('sidebar.feature') }}
                        </li>
                        <li :class="activeTab === 1 ? 'active':''" @click="activeTab = 1">
                            <i class="far fa-handshake"></i>
                            {{ $t('sidebar.services') }}
                        </li>
                        <li :class="activeTab === 2 ? 'active':''" @click="activeTab = 2">
                          <i class="ri-door-lock-box-line"></i>
                          {{ $t('rooms') }}
                        </li>
                        <li :class="activeTab === 3 ? 'active':''" @click="activeTab = 3">
                          <i class="ri-camera-lens-line"></i>
                          {{ $t('images') }}
                        </li>
                    </ul>
                </div>
                <div class="componentsData py-4">
                    <div class="featuers" v-if="activeTab === 0">
                        <b-row>
                            <b-col cols="6" class="p-1" v-for="feature in hotel.features" :key="feature.id">
                                <div class="featur p-3">
                                    <div class="title mb-2 d-flex flex-wrap align-items-center">
                                        <h6 class="">{{ feature.en_name }}</h6>
                                        <i :class="feature.icon"></i>
                                    </div>
                                    <div class="sub-feature d-flex align-items-center flex-wrap">
                                        <div v-for="sub in feature.features" :key="sub.id" class="modren-icon d-flex align-items-center mb-2">
                                            <span class="modren-icon__icon">
                                              <i class="ri-star-line"></i>
                                            </span>
                                            <span class="modren-icon__content">
                                                {{ sub.en_name }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols = '12' v-if="hotel.features && hotel.features.length === 0">
                              <h4 class="text-center d-flex align-items-center justify-content-center ga-1 text-primary py-5" style="gap: 5px;">
                                لم يتم تحديد المميزات
                                <i class="ri-close-circle-fill"></i>
                              </h4>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="services" v-if="activeTab === 1">
                        <b-row>
                            <b-col cols = "4" v-for="service in hotel.services" :key="service.id">
                                <div class="modren-icon d-flex align-items-center mb-2">
                                    <span class="modren-icon__icon">
                                    <i :class="service.icon"></i>
                                    </span>
                                    <span class="modren-icon__content">
                                        {{ service.en_name }}
                                    </span>
                                </div>
                            </b-col>
                            <b-col cols = '12' v-if="hotel.services && hotel.services.length === 0">
                              <h4 class="text-center d-flex align-items-center justify-content-center ga-1 text-primary py-5" style="gap: 5px;">
                                لم يتم تحديد خدمات
                                <i class="ri-close-circle-fill"></i>
                              </h4>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="rooms" v-if="activeTab === 2">
                        <b-row>
                            <b-col sm="12" md="6" class="mb-2" v-for="(room,index) in hotel.rooms" :key="index">
                                <div class="rooms-card " @click="goRoom(room)">
                                <div class="img-container w-100 h-100">
                                    <img :src="room.image_url || 'https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM='" alt="">
                                </div>
                                <div class="info bg-white shadow p-2">
                                    <h6 class="font-weight-bold d-flex align-items-center justify-content-between">
                                        Room Number {{room.name}}
                                        <ul class="d-flex align-items-center p-0 m-0">
                                                <li >
                                                    <span><i class="fas fa-baby"></i></span>
                                                    <span class="mx-1">{{ room.child_number }}</span>
                                                </li >
                                                <li class = "mx-1">
                                                    <i class="fas fa-male"></i>
                                                    <span class="mx-1">{{ room.audit_number }}</span>
                                                </li>
                                            </ul>
                                        </h6>
                                        <ul class="p-0 m-0">
                                            <li class="py-1 px-2 bg-light border-bottom d-flex align-items-center justify-content-between">
                                                <span><i class="fas fa-receipt"></i> Total Reserve : </span>
                                                <span class="mx-1"> {{ room.total_reserve }}</span>
                                            </li>
                                            <li class="py-1 px-2 bg-light border-bottom d-flex align-items-center justify-content-between">
                                                <span><i class="far fa-eye"></i> Total Views : </span>
                                                <span class="mx-1"> {{ room.tota_views }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols = '12' v-if="hotel.rooms && hotel.rooms.length === 0">
                              <h4 class="text-center d-flex align-items-center justify-content-center ga-1 text-primary py-5" style="gap: 5px;">
                                لم يتم أضافة غرف
                                <i class="ri-close-circle-fill"></i>
                              </h4>
                            </b-col>
                        </b-row>
                    </div>

                    <div class="imgs" v-if="activeTab === 3">
                        <div class="imgs-container">
                            <div class="img" v-for="(img,index) in hotel.images" :key="index" >
                                <img class="img-fluid mb-1 rounded border glightbox" width="100%" height="300" style="height: 200px;object-fit: cover;" :src="img.image_path" alt="">
                            </div>
                        </div>
                        <h4 v-if="hotel.images && hotel.images.length === 0" class="text-center d-flex align-items-center justify-content-center ga-1 text-primary py-5" style="gap: 5px;">
                                لم يتم رفع صور
                                <i class="ri-close-circle-fill"></i>
                              </h4>
                    </div>
                </div>
            </template>
        </iq-card>

      </div>
    </div>
</template>
<script>
import GLightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.css'

export default {
  name: 'hotels',
  data () {
    return {
      activeTab: 0
    }
  },
  methods: {
    goRoom (room) {
      localStorage.setItem('room-data', JSON.stringify(room))
      this.$router.push(`/room/${room.id}`)
    },
    toogleActive () {
      this.activeHotel(this.$route.params.id).then(() => {
        this.getHotel(this.$route.params.id)
      })
    },
    toogleShowing () {
      this.showingHotel(this.$route.params.id).then(() => {
        this.getHotel(this.$route.params.id)
      })
    }
  },
  watch: {
    activeTab (value) {
      if (value === 3) {
        setTimeout(() => {
          GLightbox({
            selector: '.glightbox'
          }) // init lightbox
        }, 100)
      }
    }
  },
  mounted () {
    this.getHotel(this.$route.params.id)
  }
}
</script>
<style lang="scss">
.hotel-info {
  padding: 10px;
  &__header {
    display: flex;
    justify-content:space-between;
    align-items: center;
  }
  &__sub {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  &__details {
    margin-top: 10px;
  }
  &__thumbnail {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    object-fit: cover;
  }
  @media (max-width: 600px) {
    &__sub {
      font-size: 10px;
    }
  }
}

#hotel_info header {
    height: 50vh;
}

#hotel_info .tabs ul{
    border-bottom: 1px  solid #ccc;
    padding: 0px !important;
    gap: 30px;
}

#hotel_info .tabs li {
    padding: 20px 5px;
    font-size: 18px;
}

#hotel_info .tabs li.active {
    border-bottom: 1px  solid var(--primary)
}

#info-section {
    margin-top: -10%;
  z-index: 99;
}

.featur {
        background-color: #f9f9f9;
        border-radius: 15px;
        margin-bottom: 10px !important;
        height: 100%;
    }
    .featur .sub-feature {
        gap:10px
    }
    .featur .content {
        border:1px solid var(--primary);
        color:var(--primary);
        border-radius: 15px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        gap:5px;
        cursor: pointer;
    }
    .featur .content.active {
        background-color:var(--primary);
        color:#fff;
    }

    .modren-icon {
        border-radius: 5px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: rgb(236, 240, 241);
        overflow: hidden;
        &__icon {
          background-color: rgb(203, 209, 210);
          padding: 5px 10px;
        }
        &__content {
          margin: 0 10px
        }
    }

    .rooms-card {
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.rooms-card img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.rooms-card .info {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  border-radius: 5px;

}

.imgs-container{
    column-count: 3;
    column-gap: 5px;
}
.add-new-img {
    font-size: 18px;
    font-weight: bold;
    color: #ccc;
    height: 200px;
    border: 2px dashed #ccc;
    cursor: pointer;
}

.imgs-container{
    column-count: 3;
    column-gap: 5px;
}
.add-new-img {
    font-size: 18px;
    font-weight: bold;
    color: #ccc;
    height: 200px;
    border: 2px dashed #ccc;
    cursor: pointer;
}
.img-preview {
    height: 200px;
    border: 2px dashed #ccc;
}
.img-preview img {
    object-fit: contain;
}

</style>
